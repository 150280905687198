<template>
  <v-card v-if="this.$store.state.qr_event_data.b"

      class="mx-auto text-center"
      max-width="344"
  >

    <v-img
        src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
        height="150px"
    >
    </v-img>

    <v-card-title class="text-center" style="display:block">
      {{ $store.state.qr_event_data.show }} [{{ $store.state.qr_event_data.event }}]
    </v-card-title>

    <v-card-subtitle center>
      {{ $store.state.qr_event_data.date }}
    </v-card-subtitle>
    <v-card-text class="text--primary">
<!--      <div>Александр Твардовский</div>-->

<!--      <div>комедия, 1 час 30 минут с одним антрактом</div>-->
    </v-card-text>

    <v-card-actions>

      <v-spacer></v-spacer>


      <v-btn
          block
          color="amber lighten-2"
          to="/begin"
      >
        <v-icon>mdi-bookmark-outline</v-icon>
        Выбрать другой
      </v-btn>




    </v-card-actions>

  </v-card>

  <v-card v-else

          class="mx-auto text-center"
          max-width="344"
  >

    <v-img
        src="https://picsum.photos/500/300?image=1"
        height="150px"
    >
    </v-img>

    <v-card-title class="text-center" style="display:block">
      Спектакль не загружен
    </v-card-title>

    <v-card-subtitle center>
      Статус [начало работы]<br>
    </v-card-subtitle>




    <v-card-actions>

      <v-btn

          color="amber lighten-4"
          to="/begin"
      >
        <v-icon>mdi-view-list</v-icon>
        Выбрать
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn

          color="amber lighten-2"
          to="/begin"
      >
        <v-icon>mdi-auto-fix</v-icon>
        Определить
      </v-btn>






    </v-card-actions>

  </v-card>

</template>

<script>
export default {
  name: "Active",

  data: () => ({
    loaded: true
  })
}
</script>

<style scoped>

</style>
