<template>
  <div>

    А если тут меню справа сделать с выбором спектаклей и возможностью продавать билеты на несколько спектаклей сразу

    {{$store.state.eventMapKassa.b}}
    <Hall type="kassa" v-if="$store.state.eventMapKassa.b" />




  </div>
</template>

<script>

import Hall from '@/components/Hall.vue'


export default {
  name: "Sell",

  components: {
    Hall
  },

  data() {
    return {

    }
  },

  created() {


    this.$store.commit('loadEventMapKassa', 436);

    console.log(this.$store.state.eventMapKassa);

  },


}
</script>

<style scoped>

</style>
