<template>
  <div >

    <div class="cache">
      <input type="checkbox" @change="handleCacheChange"> cache shapes
    </div>

    <v-stage :config="configKonva" ref="stage"
             @mousedown=  "canvas_mouse_down"
             @mousemove=  "canvas_mouse_move"
             @wheel =     "canvas_mouse_wheel">
      <v-layer>

        <v-group ref="group">

          <v-rect v-for="seat in seats"
                  :key="seat.base.id"
                  :config="seat.property"
          ></v-rect>

        </v-group>
<!--        @mousedown="seat_mouse_click(seat.base.id)"/-->


        <v-rect v-if="geometry.isShift" :config="select_rect"></v-rect>


      </v-layer>
    </v-stage>

  </div>
</template>

<script>


import Vue from 'vue';
import VueKonva from 'vue-konva';

Vue.use(VueKonva);


export default {

  name: "Hall",

  props: {
    type: {
      type: String,
    }
  },

  data() {
    return {

      text:'none',

      // мышечное выделение
      select_rect:{
        x: 0,
        y: 0,
        width: 0,
        height:0,
        fill: 'rgba(33,33,33,0.5)',
        shadowBlur:0,
        stroke: 'red',
      },

      geometry: {

        seat_width: 30,
        seat_height:30,

        // глобальное смещение
        // переделать через внутреннй translate?
        move_x :0,
        move_y:0,

        // точка нажатия мышки
        mDownX:0,
        mDownY:0,

        // для таскания карты
        // на время таскания эта переменная (после mousemove - обнуляется)
        move_tX:0,
        move_tY:0,

        // зажат шифт
        isShift      : false,
        // любая мышь зажата
        isDown       : false,

        scale        : 1,
      },

      // массив мест со стандартными полями (придумать стандарт =))
      seats:[],

      // вид одного кресла
      seat:{

        // свойства конвы
        property:{
          x: 0,
          y: 0,
          width: 30,
          height:30,
          fill: 'blue',
          shadowBlur:10,
        },


        base:{
          id:         0,
          x:          0,
          y:          0,
          sector:     0,
          row:        0,
          chair:      0,
        },

        flag:{
          free:       false,
          selected:   false,
          hover:      false,
          canSelect:  false
        },

        additional:{
          price_zone: 0,
        },


      },


      configKonva: {
        width:  1200,
        height: 600,
        draggable: false

      },
      configBG: {
        x: 0,
        y: 0,
        width: 1200,
        height:600,
        fill: 'white',
      }
    };
  },

  created() {
    console.log('HALL has been created!');
    //this.$store.dispatch('loadGlobal');
    this.clearVar();
    this.generateFakeSeats();

    console.log(this.$store.state.eventMapKassa);

    this.prepareSeats(this.$store.state.eventMapKassa.data.seats);

  },

  mounted(){
    //this.$refs.stage.addEventListener('mousemove', this.canvas_mouse_move, false);
    window.addEventListener('mouseup', this.canvas_mouse_up);
    this.full_redraw();
  },

  methods: {

    handleCacheChange(e) {
      const shouldCache = e.target.checked;
      if (shouldCache) {
        this.$refs.group.getNode().cache();
      } else {
        this.$refs.group.getNode().clearCache();
      }

      this.configKonva.draggable = shouldCache;

    },


    seat_mouse_click(sid){
      console.log('click '+sid);
      //this.geometry.isDown = false;

      if (this.seats[sid].flag.canSelect){
        this.seats[sid].flag.selected = !this.seats[sid].flag.selected;
        this.$forceUpdate
      }

    },

    seat_mouse_enter(sid){
      this.seats[sid].flag.hover = true;
      this.seats[sid].property.fill = '#b3defd';

    },

    seat_mouse_leave(sid){
      this.seats[sid].flag.hover = false;
      this.full_redraw();
    },





    clearVar(){
      console.log('cleared all')

      this.geometry = {
        seat_width :  30,
        seat_height : 30,
        move_x :      0,
        move_y :      0,
        mDownX :      0,
        mDownY :      0,
        move_tX :     0,
        move_tY :     0,
        isShift :     false,
        isDown :      false,
        scale :       1,
      }

      console.log(this.geometry);
    },

    canvas_mouse_wheel(event){

      console.log('whell');
      console.log(this.geometry.scale);

      if (event.evt.wheelDelta>0){
        this.geometry.scale +=0.05;
      }
      else{
        this.geometry.scale -=0.05;
      }
      console.log(this.geometry.scale);
      this.full_redraw();

      event.evt.preventDefault();


    },

    canvas_mouse_up(){

      //console.log(event);
      //console.log(this.select_rect);

      this.geometry.isDown = false;
      this.geometry.isShift = false;

      this.geometry.move_x += this.geometry.move_tX;
      this.geometry.move_y += this.geometry.move_tY;

      this.geometry.move_tX = 0;
      this.geometry.move_tY = 0;

      this.select_rect.x      = 0;
      this.select_rect.width  = 0;
      this.select_rect.y      = 0;
      this.select_rect.height = 0;

      this.full_redraw();

    },

    canvas_mouse_down(event){

      //console.log(this.seats);
      //console.log(self);
      //console.log(event);

      // нажали в пустое место (значит тащим карту)
      let needMove = true;


      if (event.evt.shiftKey){
        console.log('shift');
        this.geometry.isShift = true;

        this.select_rect.x = event.evt.offsetX;
        this.select_rect.y = event.evt.offsetY;

        needMove = false
      }

      //мы делаем рамку, нам не надо уже координаты нажатия вычис
      if (needMove){

        //console.log(event.evt.offsetX,              event.evt.offsetY)

        // // переберем места, посмотрим куда нажал
        this.seats.forEach(function(seat){

          if (seat.flag.canSelect &&
              this.inRect(event.evt.offsetX,              event.evt.offsetY,
                  seat.property.x,                 seat.property.y,
                  seat.property.x + seat.property.width, seat.property.y+ seat.property.height)
          ){
            seat.flag.selected = !seat.flag.selected;
            needMove = false;
          }

        }, this);
      }

      // нажали в пустое место - тащим карту
      if (needMove){

        //
        this.geometry.mDownX = event.evt.clientX;
        this.geometry.mDownY = event.evt.clientY;

        //move_temp_X = 0;
        //move_temp_Y = 0;

        this.geometry.isDown = true;
      }

      //this.full_redraw();

    },

    canvas_mouse_move(event){
      //console.log('move');

      //const mousePos = this.$refs.stage.getNode().getPointerPosition();

      // подсвечиваем, где мышью пробегаем
      // подумать надо оптимизацией, чтоб не каждое движение мышкой массив перебирать и отрисовывать все кресла
      // seats_n.forEach(function(seat){
      // 	seat.hover = inRect(e.offsetX, e.offsetY, seat.x, seat.y, seat.x+seat_width, seat.y+seat_height);
      // });
      // all_seat_draw();



      if (this.geometry.isDown){

        //console.log(e);
        //console.log(mDownX);
        //console.log(e.clientX);

        this.geometry.move_tX = this.geometry.mDownX - event.evt.clientX;
        this.geometry.move_tY = this.geometry.mDownY - event.evt.clientY;

        this.full_redraw();

      }

      if (this.geometry.isShift){

        //this.full_redraw();

        //console.log('shoft');

//        this.ctx.fillStyle = "rgba(33,33,33,0.5)";

        this.select_rect.width  = event.evt.offsetX;
        this.select_rect.height = event.evt.offsetY;

        this.select_rect.width  = event.evt.offsetX - this.select_rect.x;
        this.select_rect.height = event.evt.offsetY - this.select_rect.y;


        //console.log(this.select_rect);
        //this.ctx.fillRect(this.mouseRectX1, this.mouseRectY1, mouseW, mouseH);

        //console.log(this.seats);
        //выделяем кресла
        this.seats.forEach(seat=>this.seatCanSelect(seat));



      }

    },

    seatCanSelect(seat){

      let r1x1 = this.select_rect.x;
      let r1x2 = r1x1 + this.select_rect.width;
      let r1y1 = this.select_rect.y;
      let r1y2 = r1y1 + this.select_rect.height;

      // определяем при выделении снизу ввех и слева-направо
      if (r1x2<r1x1){
        let t = r1x1
        r1x1 = r1x2;
        r1x2 = t;
      }
      if (r1y2<r1y1){
        let t = r1y1
        r1y1 = r1y2;
        r1y2 = t;
      }

      let r2x1 = seat.property.x;
      let r2x2 = r2x1 + seat.property.width;
      let r2y1 = seat.property.y;
      let r2y2 = r2y1 + seat.property.height;


      if (seat.flag.canSelect &&
          this.haveIntersection2(
              r1x1,   r1x2,
              r1y1,   r1y2,
              r2x1,   r2x2,
              r2y1,   r2y2
              )){
        seat.flag.selected = true;
        seat.property.fill = 'gray';
        //console.log('aa');
      }

    },

    inRect(x,y, rectX1, rectY1, rectX2, rectY2){

      // let beginX  = rectX1*this.geometry.scale + this.geometry.move_x + this.geometry.move_tX;
      // let beginY  = rectY1*this.geometry.scale + this.geometry.move_y + this.geometry.move_tY;
      // let endX    = rectX2*this.geometry.scale + this.geometry.move_x + this.geometry.move_tX;
      // let endY    = rectY2*this.geometry.scale + this.geometry.move_y + this.geometry.move_tY;

      let beginX  = rectX1;
      let beginY  = rectY1;
      let endX    = rectX2;
      let endY    = rectY2;

      return  (x > beginX) &&
          (x < endX) &&
          (y > beginY) &&
          (y < endY);

    },


    haveIntersection2(r1x1, r1x2, r1y1, r1y2, r2x1, r2x2, r2y1, r2y2) {

      //console.log(r1x1, r1x2, r1y1, r1y2, r2x1, r2x2, r2y1, r2y2)

      return !(
          r2x1 > r1x2 ||
          r2x2 < r1x1 ||
          r2y1 > r1y2 ||
          r2y2 < r1y1
      );
    },


    full_redraw(){

      //console.log('fd');

      this.$nextTick(() => {

        this.seats.forEach(function (seat) {

          //console.log(this);

          seat.property.fill = "#0737ec";

          if (!seat.flag.free) {
            seat.property.fill = "#ff1149";
          }

          if (seat.flag.canSelect) {
            seat.property.fill = "#7fd003";
          }

          if (seat.flag.selected) {
            seat.property.fill = "#ffa810";
          }

          if (seat.flag.hover) {
            seat.property.fill = "#b3defd";
          }

          //console.log(this.geometry.move_tX, this.geometry.move_x);
          //console.log(seat.property.x, seat.property.y,  this.geometry.scale);

          // seat.property.x = this.geometry.move_tX + this.geometry.move_x + seat.property.x * this.geometry.scale;
          // seat.property.y = this.geometry.move_tY + this.geometry.move_y + seat.property.y * this.geometry.scale;

          seat.property.x = this.geometry.move_tX + this.geometry.move_x + seat.base.x * this.geometry.scale;
          seat.property.y = this.geometry.move_tY + this.geometry.move_y + seat.base.y * this.geometry.scale;

          // seat.property.x = seat.property.x * this.geometry.scale;
          // seat.property.y = seat.property.y * this.geometry.scale;

          // seat.property.x =  seat.property.x * this.geometry.scale;
          // seat.property.y =  seat.property.y * this.geometry.scale;

          // seat.property.width   = seat.property.width * this.geometry.scale;
          // seat.property.height  = seat.property.height * this.geometry.scale;

          seat.property.width   = this.geometry.seat_width * this.geometry.scale;
          seat.property.height  = this.geometry.seat_height * this.geometry.scale;

          //console.log(seat.property.x, seat.property.y);


          // надпись
          //   this.ctx.fillText( seat.seat,
          //       this.move_temp_X + this.move_x + seat.x*this.scale + this.seat_width*this.scale/2,
          //       this.move_temp_Y + this.move_y + seat.y*this.scale + this.seat_height*this.scale - 8,
          //       this.seat_width);
          //
          // }, this);
        }, this)

      })

      //this.geometry.scale = 1;

      //console.log('fd_end')

    },

    prepareSeats(api_seats){

      this.seats = [];
      console.log(api_seats);

      for (let key in api_seats){

        let a_seat = api_seats[key];

        let seat = {

          property:{
            x: a_seat.left,
            y: a_seat.top,
            width: 30,
            height:30,
            fill: 'blue',
            // shadowBlur:10,
            stroke:'black',
            // text: '1',
            // fillLinearGradientStartPoint: { x: -50, y: -50 },
            // fillLinearGradientEndPoint: { x: 50, y: 50 },
            // fillLinearGradientColorStops: [0, 'red', 1, 'yellow']
          },

          base:{
            id:         a_seat.id,
            x:          a_seat.left,
            y:          a_seat.top,
            sector:     a_seat.sector_id,
            row:        a_seat.row,
            chair:      a_seat.chair,
          },

          flag:{
            free:       a_seat.isFree,
            selected:   false,
            hover:      false,
            canSelect:  a_seat.isFree,
          },

          additional:{
            price_zone: a_seat.zone,
          },


        };

        this.seats.push(seat);

      }
      //
      // api_seats.forEach(a_seat=>{
      //
      //   let seat = {
      //
      //     property:{
      //       x: a_seat.left,
      //       y: a_seat.top,
      //       width: 30,
      //       height:30,
      //       fill: 'blue',
      //       shadowBlur:10,
      //       stroke:'black',
      //       // text: '1',
      //       // fillLinearGradientStartPoint: { x: -50, y: -50 },
      //       // fillLinearGradientEndPoint: { x: 50, y: 50 },
      //       // fillLinearGradientColorStops: [0, 'red', 1, 'yellow']
      //     },
      //
      //     base:{
      //       id:         a_seat.id,
      //       x:          a_seat.left,
      //       y:          a_seat.top,
      //       sector:     a_seat.sector_id,
      //       row:        a_seat.row,
      //       chair:      a_seat.chair,
      //     },
      //
      //     flag:{
      //       free:       a_seat.isFree,
      //       selected:   false,
      //       hover:      false,
      //       canSelect:  a_seat.isFree,
      //     },
      //
      //     additional:{
      //       price_zone: a_seat.zone,
      //     },
      //
      //
      //   };
      //
      //   this.seats.push(seat);
      //
      // });

      console.log(this.seats);



    },

    generateFakeSeats(){

      this.seats = [];


      let xx = 0;
      let yy = 300;


      for (var i = 0; i < 40; i++) {

        let ss = true;
        if ((i==4) || (i==17)){
          ss = false;
        }

        if (i>20){
          yy = 340;
          xx = 40*20;
        }

        let seat = {

          property:{
            x: 40*i-xx+250,
            y: yy,
            width: 30,
            height:30,
            fill: 'blue',
            shadowBlur:10,
            stroke:'black',
            // text: '1',
            // fillLinearGradientStartPoint: { x: -50, y: -50 },
            // fillLinearGradientEndPoint: { x: 50, y: 50 },
            // fillLinearGradientColorStops: [0, 'red', 1, 'yellow']
          },

          base:{
            id:         i,
            x:          40*i-xx+250,
            y:          yy,
            sector:     i,
            row:        i,
            chair:      i,
          },

          flag:{
            free:       false,
            selected:   false,
            hover:      false,
            canSelect:  ss
          },

          additional:{
            price_zone: 0,
          },


        };

        this.seats.push(seat);

      } //for

      console.log(this.seats);






    },
  }

}
</script>

<style scoped>

</style>
